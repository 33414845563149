<template>
    <div class="video">
    	<!-- 使用组件 -->
      <video-player  class="video-player vjs-custom-skin"
           ref="videoPlayer"
           :playsinline="true"
           :options="playerOptions"
      ></video-player>
    </div>
</template>
<script>
// 以下三行一定要引入
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
// import 'video.js/dist/lang/zh-CN'
 
export default {
	// name: 'videoplayer',
	components: { // 必需引入
		videoPlayer
    },
    props: {
        videoUrl: {
            type: String,
            required: false,
        }
    },
	data () {
		return {
			fileAreaHeight: 675,
			fileType: 'mp4', // 资源的类型
		}
	},
	computed: { // 使用计算属性
	    playerOptions () {
            const playerOptionsObj = {
                playbackRates: [0.5, 1.0, 1.5, 2.0],  // 可选的播放速度
                autoplay: false,  // 如果为true,浏览器准备好时开始回放。
                muted: true,  // 默认情况下将会消除任何音频。
                loop: true,  // 是否视频一结束就重新开始。
                preload: 'auto',  // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '192:55',  // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true,  // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: "video/mp4",  // 类型
                    src: this.videoUrl || "https://saidao.cdls666.com/api/upfiles/sys/20239/25/79dbd6b5-e939-4fe5-a115-2f1a099ca780.mp4"  // url地址
                    // src: this.videoUrl || ""  // url地址
                }],
                poster: '',  // 封面地址
                width: '100%',
                notSupportedMessage: '此视频暂无法播放，请稍后再试',  // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: false,  // 当前时间和持续时间的分隔符
                    durationDisplay: false,  // 显示持续时间
                    remainingTimeDisplay: false,  // 是否显示剩余时间功能
                    fullscreenToggle: false  // 是否显示全屏按钮
                }
            }
            return playerOptionsObj
        }
    },
    created() {
        setTimeout(() => {
            this.$refs.videoPlayer.player.load()
            this.$refs.videoPlayer.player.play()
        },500)
    },
    watch: {
 
    }
  }
</script>
<style lang="less">
    .video-player {
        height: 100%;
        width: 100%;
        video {
            object-fit: fill;
            width: 100%;
            height: 100%;
        }
        .video-js {
            height: 100%;
            padding: 0;
        }
        .video-js .vjs-big-play-button {
            display: none !important;
        }
        .vjs-control-bar {
            display: none !important;
        }
    }
</style>